// Honeybadger config

import pkg from '../../../package.json'

const env = import.meta.env ? import.meta.env : process.env

const apiKey = 'hbp_eraWdz8ohn121NncUw68lc14ZYlSTo14aLgK'
const environment = env.HONEYBADGER_ENVIRONMENT || 'development'
const revision = env.VITE_REVISION || pkg.version

const assets = {
    development: 'https://localhost:7533/static/static/assets/',
    staging: 'https://test.treebuddy.earth/static/assets/',
    production: 'https://www.treebuddy.earth/static/assets/',
}

const assetsUrl = assets[environment]

export const honeybadgerConfig = {
    apiKey,
    environment,
    revision,
}

// Docs for Honeybadger Rollup plugin options:
// https://github.com/honeybadger-io/honeybadger-js/tree/master/packages/rollup-plugin#configuration
export const honeybadgerPluginOptions = {
    apiKey,
    assetsUrl,
    revision,
    deploy: {
        environment,
        repository: 'https://gitlab.com/treebuddy.earth/treebuddy/',
    },
}
